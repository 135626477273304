@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@import "./@vex/styles/core";

:root {
  --clash-500: "ClashGrotesk-Medium", sans-serif;
  --clash-600: "ClashGrotesk-Semibold", sans-serif;
  --default-font: #111827;
  --satoshi: "Satoshi-Regular", sans-serif;
  --brand-primary: #6752A9;
}

.cursor-pointer {
  cursor: pointer;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.p-100px {
  padding: 100px;
}

.p-15px {
  padding: 15px !important;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: end !important;
}

.text-info {
  color: #007bff;
}

.text-danger {
  color: #dc3545;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-bold {
  font-weight: 600;
}

.text-italic {
  font-style: italic;
}

.align-items-center {
  align-items: center;
}

.f-20px {
  font-size: 20px;
}

.m-auto {
  margin: auto;
}

.icon-position {
  margin-block: -5px;
}

.img-size {
  //height: 18%;
  //width: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.mat-mdc-snack-bar-container {
  &.background-danger {
    --mdc-snackbar-container-color: #dc3545;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mat-mdc-snack-bar-container {
  &.background-success {
    --mdc-snackbar-container-color: #04AA6D;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mt-50px {
  margin-top: 50px;
}

.mt-100px {
  margin-top: 100px;
}

.mt--20px {
  margin-top: -20px !important;
}

.shadow-light {
  box-shadow: 0 0 1px 1px #e0e0e0;
}

.shadow-light-up {
  box-shadow: 0 -1px 0 0 #e0e0e0;
}

.resize-none {
  resize: none;
}

.search-form {
  display: flex;
  justify-content: center;
}

.search-input {
  outline: none;
  background: rgba(248, 248, 248, 0.72);
  padding: 15px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px #e0e0e0;
}

.search-btn {
  width: 100%;
  padding: 15px;
}

.h-32px {
  height: 32px !important;
}

.f_10px {
  font-size: 10px !important;
}

.f_12px {
  font-size: 12px !important;
}

.f_18px {
  font-size: 18px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.bg-btn-blue {
  background-color: #305684;
  color: #ffffff;
}

.icon-height {
  height: 16px;
  width: 16px;
}

/* Total width of the scrollbar */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Background of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Foreground (draggable part) of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Hover state for the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Optional: Styling the scrollbar buttons */
::-webkit-scrollbar-button {
  background: #ccc;
  display: block;
  height: 13px;
}

::-webkit-scrollbar-button:single-button {
  background: #ccc;
}

.fa-circle-check {
  cursor: pointer;
  font-size: 24px;
}

.fa-circle-check.selected {
  color: green;
}

.timeline {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 5% auto;
}


.timeline__event {
  display: flex;
  position: relative;

}

.timeline__event__line {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  background: #9251ac;
  z-index: 1;
}

.timeline__event__date {
  color: #87bbfe;
}

.timeline__event__icon {

  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #9251ac;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  position: relative;
  top: 0px;
  z-index: 2;
}

.timeline__event__content {
  margin-bottom: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
  0 18px 36px -18px rgba(0, 0, 0, 0.3),
  0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  padding: 20px;
  flex: 1;
  margin-left: 40px;
}

.timeline__event--type2 .timeline__event__icon {
  background: #555ac0;
}

.timeline__event--type2 .timeline__event__line {
  background: #555ac0;
}

.timeline__event--type2 .timeline__event__title {
  color: #555ac0;
}

.timeline__event--type3 .timeline__event__icon {
  background: #24b47e;
}

.timeline__event--type3 .timeline__event__line {
  background: #24b47e;
}

.timeline__event--type3 .timeline__event__title {
  color: #24b47e;
}

.timeline__event__title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #9251ac;
  letter-spacing: 1.5px;
}

.timeline__event--type2 .timeline__event__icon {
  background: #555ac0;
}

.timeline__event--type2 .timeline__event__line {
  background: #555ac0;
}

.timeline__event--type3 .timeline__event__icon {
  background: #24b47e;
}

.timeline__event--type3 .timeline__event__line {
  background: #24b47e;
}

@media (max-width: 786px) {
  .timeline {
    width: 100%;
    margin: 0 auto;
  }

  .timeline__event {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 50px; /* Add padding to create space for icons and lines */
    position: relative; /* Add position relative to .timeline__event */
  }

  .timeline__event__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #9251ac;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; /* Decrease right margin */
  }

  .timeline__event__line {
    position: absolute;
    top: 20px;
    left: 20px; /* Adjust left position */
    width: 2px;
    height: calc(100% - 20px); /* Adjust height to cover the content */
    background: #9251ac;
    z-index: 1;
  }

  .timeline__event__content {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3),
    0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    padding: 20px 70px;
    flex: 1;
    margin-left: 5px; /* Adjust margin left */
    z-index: 2;
  }

  .timeline__event__title {
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 600;
    color: #9251ac;
    letter-spacing: 1.5px;
  }

  .timeline__event__description {
    margin-top: 10px;
  }

  .timeline__event__date {
    color: gray;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;

  }
}

.timeline__event__date {
  color: gray;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}

@media (max-width: 576px) {
  .timeline {
    width: 100%;
    margin: 0 auto;
  }

  .timeline__event {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 50px; /* Add padding to create space for icons and lines */
    position: relative; /* Add position relative to .timeline__event */
  }

  .timeline__event__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #9251ac;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; /* Decrease right margin */
  }

  .timeline__event__line {
    position: absolute;
    top: 20px;
    left: 20px; /* Adjust left position */
    width: 2px;
    height: calc(100% - 20px); /* Adjust height to cover the content */
    background: #9251ac;
    z-index: 1;
  }

  .timeline__event__content {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3),
    0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    padding: 20px;
    flex: 1;
    margin-left: 0; /* Adjust margin left */
    z-index: 2;
  }

  .timeline__event__title {
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 600;
    color: #9251ac;
    letter-spacing: 1.5px;
  }

  .timeline__event__description {
    margin-top: 10px;
  }

  .timeline__event__date {
    color: gray;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    right: 50px;
    top: 50px;
  }
}

.color-white {
  color: #ffffff;
}

.parent-container {
  display: flex;
  flex-direction: column;
}

.header-color {
  background: linear-gradient(to right, #3F60BD, #E2C7DC)
}

@font-face {
  font-family: 'Inter';
  src: url("assets/font/Inter/static/Inter-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.font-inter {
  font-family: 'Clash Grotesk', sans-serif !important;
}

body, html {
  margin: 0;
  padding: 0;
  background: #f6f9fc;
  font-family: "inter-var", sans-serif;
  color: #525f7f;
}

.navbar {
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: auto;
}

@media (min-width: 992px) and (max-width: 1400px) {
  .navbar {
    height: 80px;
  }
}

@media (min-width: 992px) {
  .navbar {
    height: 80px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar {
    height: 80px;
  }
}

/*.h-80px{
  height: 80px;
}*/
.navbar ul {
  overflow: auto;
}

.navbar li {
  float: right;
  list-style: none;
  margin: 30px 10px;
  padding: 20px 20px;
}

.navbar li a {
  color: #111827;
}

.home-one {
  Height: 600px;
}

.background-home {
  background-image: url("assets/images/upper-bg.png");
  background-size: cover;
}

.mt-300px {
  margin-top: 300px;
}

.fs-30 {
  font-size: 30px;
}

.image-style {
  width: 180px;
  height: 80px;
  padding: 5px;
}

.fs-15 {
  font-size: 15px;
}

.mt-50px {
  margin-top: 50px;
}

.button-style {
  width: 250px;
  height: 48px;
  border-radius: 4px;
  background-color: #6752A9;
  color: #FFFFFF;
  border: none;
  transition: box-shadow 0.3s ease;
}

.fw-400 {
  font-weight: 400;
}

.fs-10 {
  font-size: 0.85rem;
}

.fw-500 {
  font-weight: 500;
}

.fs-40 {
  font-size: 2.5rem;
}

.mt-100px {
  margin-top: 100px;
}

.btn-contact {
  width: 160px;
  height: 40px;
  border-radius: 4px;
  color: #FFF;
  background-color: #6752A9;
  border: none;
  font-size: 16px;
  padding-right: 5px;
}

.btn-contact:hover {
  color: #305684;
  border: 1px solid #305684;
}

/*.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}*/

.bg-color {
  background-color: #FFF1F1;
}

.white-color {
  color: #FFFFFF;
}

.bg-black {
  background-color: #161C2D !important;
}

.back-color {
  background-color: #161C2D;
}

.color-blue {
  color: #305684;
}

.color-light-blue {
  background-color: #E6F4FF;
}

.btn-size {
  width: 272px;
  height: 64px;
  background-color: #F0F4FF;
  border-radius: 8px;
}

.icon-plus {
  color: #305684;
}

.bg-blue {
  background-color: #6752A9;
}

.blue-color {
  color: #0066FF;
}

.card-size {
  height: 80px;
  border-radius: 8px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid black;
  transition: background-color 1s;
}

.line {
  border-left: 2px dotted black;
  height: 50px;
  margin-left: 24px;
}

#text1, #text2, #text3, #text4 {
  text-align: center;
  transition: color 1s;
}

.background-black {
  background-color: #161C2D;
}

.pl-100px {
  padding-left: 180px;
}

.ml-11px {
  margin-left: 11px;
}

.ml-15px {
  margin-left: 15px !important;
}

.faq-image {
  height: 350px;
  width: 1800px;
}

.color-yellow {
  color: #EA8C00;
}

.nav-item:hover {
  border-bottom: 2px solid #3465fc;
}

.fw-50 {
  font-weight: 500;
}

.nav-link:hover {
  color: #3465fc;
  pointer-events: none;
  cursor: default;
}

.bg-dark-blue {
  background-color: #305684 !important;
}

.bg-enter-details {
  background-image: url("./assets/images/bg-dark-blue.png");
  background-size: cover;
}

.bg-add-org {
  background-image: url("assets/images/bg-sky-blue.png");
  background-size: cover;
}

.circled-icon {
  width: 30px;
  height: 30px;
  padding-top: 6px;
  border-radius: 30px;
  border: 1px solid #FFFFFF;
}

.text-between {
  background-image: url("assets/images/bg-sky-blue.png");
  padding: 0 10px;
}

.highlight-button {
  cursor: pointer !important;
  overflow: hidden !important;
  position: relative !important;

}

.highlight-button::before {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  left: -100% !important;
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.3), transparent) !important;
  transition: all 0.5s !important;
}

.highlight-button:hover::before {
  left: 100% !important;
}

.f-h-size {
  font-size: 16px !important;
}

.f-p-size {
  font-size: 15px !important;
}

.bg-about {
  background-image: url("assets/images/About-us.png");
  background-size: cover;
}

.card-bg {
  background-color: #F9F9F9;
}

.box-title {
  width: 85px;
  height: 23px;
  border-left: 10px solid #FFB629;
  background-color: #041C37;
}

.bg-pricing-plans {
  background-image: url('assets/images/Pricing-&-plans-banner.png');
  background-size: cover;
}

.bg-light-grey {
  background-color: #F9F9F9;
}

.table-header-color {
  background: linear-gradient(to right, #3F60BD, #E2C7DC)
}

.bg-dark-grey {
  background-color: #f0f0f0 !important;
}

.toolbar-select-option {
  background-color: #f9f9f9;
  padding: 5px 20px 0 10px;
  height: 35px;
  border-radius: 0;
  box-shadow: 0 0 1px 1px #e0e0e0;
}

.delete-forever-icon {
  transform: scale(3) !important;
}

.selected-button {
  background-color: #655BE7 !important;
  color: #FFFFFF !important;
}

.default {
  color: black !important;
}

.mat-button-toggle-group {
  height: 45px;
  align-items: center;
}
